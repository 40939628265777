body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.error{
    color:red;
    font-size:14px;
}
.pagination{
  align-items: flex-end;
  justify-content: flex-end;
}

.pagination .page-item .page-link, .page-item span{
  padding: 3px !important;
  width: 46px !important;
  margin: 0 !important;
  border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  -ms-border-radius: 0 !important;
  -o-border-radius: 0 !important;
}
.react-bootstrap-table-pagination{
  margin-top: 20px;
}

.pagination .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}
.pagination .page-item:last-child .page-link {
  margin-left: 0;
  border-top-right-radius: 00.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}